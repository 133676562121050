import React, { Suspense, useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Canvas, useFrame } from '@react-three/fiber'
import { a, useTransition, useSpring } from '@react-spring/three'
import create from 'zustand'
import {
  BoxBufferGeometry,
  ConeGeometry,
  CylinderBufferGeometry,
  IcosahedronBufferGeometry,
  OctahedronGeometry,
  SphereBufferGeometry,
  TetrahedronBufferGeometry,
  TorusBufferGeometry,
} from 'three/src/geometries/Geometries'
import { MeshStandardMaterial } from 'three/src/materials/Materials'

import type { Group } from 'three'

import { Model } from '../components/presentation/Hero'
import Layout from '../components/layout'
import Seo from '../components/plumbing/Head'
import ElysiumCarousel from '../components/presentation/ElysiumSlides'
import { Link } from 'gatsby'
import { Fade } from 'react-awesome-reveal'
import CreatorImg01 from '../assets/images/creators/01.jpg'
import CreatorImg02 from '../assets/images/creators/02.jpg'
import CreatorImg03 from '../assets/images/creators/03.jpg'
import CreatorImg04 from '../assets/images/creators/04.jpg'
import CreatorImg05 from '../assets/images/creators/05.jpg'
import ProducerImg01 from '../assets/images/producers/01.jpg'
import ProducerImg02 from '../assets/images/producers/02.jpg'
import ProducerImg03 from '../assets/images/producers/03.jpg'
import ProducerImg04 from '../assets/images/producers/04.jpg'
import ProducerImg05 from '../assets/images/producers/05.jpg'
import elysium from '../assets/images/Elysium.png'
import elysiumCloud from '../assets/images/ElysiumCloud.png'
import elysiumPortal from '../assets/images/ElysiumPortal.png'
import geistEngine from '../assets/images/GeistEngine.png'
import terraMachina from '../assets/images/TerraMachina.png'

const creator01 = (
  <>
    <p>
      ELYSIUM's <b>Terra Machina</b> toolkit empowers you to effortlessly design your worlds and populate them
      with content. Being physically present in the creative space and moment, build immersive experiences by
      placing and configuring your digital assets in the real world. ELYSIUM understands the physical spaces
      as your build <b>geolocated</b> and <b>persistent</b> digital worlds. Our <b>no-code</b> and unique{' '}
      <b>presence design</b> workflow allows anyone to intiuitively master the creation of tangible
      relationships between digital and real-space.
    </p>
  </>
)

const creator02 = (
  <>
    <p>
      In ELYSIUM you'll create <b>deep experiences</b> to be able to tell your interactive stories like never
      before in Augmented Reality. ELYSIUM’s no-code logic engine: the <b>GEIST Engine</b>, structures and
      allows you to construct your experiences as <b>deep spatial storytelling</b>
      experiences. Through our unique <b>Physical Programming system</b>, you can make tangible the meaningful
      relationships between users, the world, and digital assets to drive true <b>sustained engagement</b> and
      gamefied interactive play with your audiences.
    </p>
  </>
)

const creator03 = (
  <>
    <p>
      With ELYSIUM, the worlds you create will be <b>geolocated + persistent</b>. They are{' '}
      <b>Persistent Worlds</b> of stories and experiences in the spaces that you define. On the{' '}
      <b>ELYSIUM Cloud</b>, these worlds are synced and distributed in real-time to allow for a{' '}
      <b>Persistence of Experience</b> for players and audiences. This means that your created worlds are
      live, responsive, and have their own histories as reflections of user engagements. Interactive
      experiences become <b>living stories</b> that allow users to return, revisit, and rediscover anew from
      where they left off.
    </p>
  </>
)

const creator04 = (
  <>
    <p>
      ELYSIUM <b>empowers creation</b> of meaningful worlds and spaces for people to{' '}
      <b>connect, play, and exchange</b>. In bringing people together in physical spaces we share points of
      view, start conversations, and through this new dimension of AR, <b>create new value</b> in the real
      world. Users engage through our social integrations, and may also leave tangible traces in the world for
      the subsequent visitors to discover. This allows audiences to <b>participate</b> in and enliven the
      stories of the worlds you create in the wild.
    </p>
  </>
)

const creator05 = (
  <>
    <p>
      The <b>ELYSIUM Portal</b> accessed through either our app or the web, is the platform that connects
      creators to a <b>community</b> and global audience. Users may explore published worlds, engage with
      complimentary content, collect and trade assets from their personal library on the marketplace, and
      participate in a growing community of creators. <b>Creators are incentivized</b>
      with new possibilities to <b>develop revenue streams</b> by building audiences, monetizing their worlds,
      connecting to curators, and by selling their verified assets on the online marketplace.
    </p>
  </>
)

const producer01 = (
  <>
    <p>
      ELYSIUM’s unique <b>embodied and no-code</b> workflow leverages our innate sense of <b>presence</b> to
      allow for an intuitive spatialized WYSIWYG experiential design. With live connectivity to the cloud,
      creators can <b>collaborate together in real-time and space</b>. Without any need for developers,
      producers and designers can focus on creative aspects of a project by simultaneously translating and
      living their ideas instantaneously in the wild.
    </p>
  </>
)

const producer02 = (
  <>
    <p>
      In ELYSIUM, user activity within worlds may be tokenized to for a <b>Persistence of Experience</b>. User
      interaction history enriches player experience by allowing producers to design lasting and{' '}
      <b>meaningful cause-effect relationships</b>. This forms the basis for <b>gamefied experiences</b> where
      user progress and scores are possible. Designers can easily build innovative reward and levelling
      systems that drive <b>continuous engagment</b> or even "play-to-earn” models.
    </p>
  </>
)

const producer03 = (
  <>
    <p>
      Built wiith <b>collaborative worldbuilding</b> in mind, ELYSIUM’s permissions and publications system
      allows <b>teams</b> to collaborate and produce in real-time and place. Successive roll-out of
      experiences is possible as you build and grow your worlds as rich interactive <b>content channels</b>.
      Once a scene is ready, it is easily deployed and made live with a <b>single click of a button</b>.
      Visitors of your worlds can subscribe and be notified with an invitation to explore the newest chapter
      of your story.
    </p>
  </>
)

const producer04 = (
  <>
    <p>
      Professional plans on ELYSIUM open up new <b>monetization channels</b> for your experiences. You may add
      paywalls to specific projects and/or scenes, or permit in-game transactions or purchases. Through our{' '}
      <b>deep-linking</b> capabilities, users may be directed to other scenes or projects, or even external
      websites to allow for <b>immeditate conversion</b> of interactions. Tokenization of user activity allows
      for advanced design for customer <b>fidelity programs</b> that can be based on interaction or location
      (or both).
    </p>
  </>
)

const producer05 = (
  <>
    <p>
      <b>Interoperability</b> is our key to open creative access. Any work you create in ELYSIUM, all the
      assets and experiences, are owned by you as creators. With a spirit of collaboration within the larger
      ecosystem, we’re establishing a new intermediary file standard that packages and exports ELYSIUM worlds
      and allows developers to bring it into Unity 3D. Once in Unity you will be able to to add{' '}
      <b>custom functions and features</b> as they develop for <b>any XR platform</b> through our low-code
      Unity SDK.
    </p>
  </>
)

const producer06 = (
  <>
    <p>
      In building custom applications through the ELYSIUM-Unity SDK pipeline, producers may also{' '}
      <b>monetize their development</b>
      of custom interactions, animations or mechanics by packaging them as <b>ELYSIUM features</b>, and
      re-selling them on the ELYSIUM portal’s
      <b>asset exchange</b>. Features are then licensed to other creators to augment their own custom
      expereinces. Does it already exist? Check the exchange. If not, create it and <b>get paid</b> for your
      contribution to the growing open development community.
    </p>
  </>
)

const creatorData = [
  {
    index: 0,
    headline: 'Place + organize digital assets as live real world experiences',
    body: creator01,
    src: CreatorImg01,
    logo: terraMachina,
  },
  {
    index: 1,
    headline: 'Design immersive journeys through real interactions',
    body: creator02,
    src: CreatorImg02,
    logo: geistEngine,
  },
  {
    index: 2,
    headline: 'Create living stories in the wild',
    body: creator03,
    src: CreatorImg03,
    logo: elysiumCloud,
  },
  {
    index: 3,
    headline: 'Share your worlds. Seed conversation and real connections',
    body: creator04,
    src: CreatorImg04,
    logo: elysium,
  },
  {
    index: 4,
    headline: 'Monetize your creations + exchange with the creator community',
    body: creator05,
    src: CreatorImg05,
    logo: elysiumPortal,
  },
]

const producerData = [
  {
    index: 0,
    headline: 'Leverage embodied design for prototyping in the wild',
    body: producer01,
    src: ProducerImg01,
    logo: terraMachina,
  },
  {
    index: 1,
    headline: 'Design persitent interactions + gamified experiences',
    body: producer02,
    src: ProducerImg02,
    logo: geistEngine,
  },
  {
    index: 2,
    headline: 'Build experiences as a team. Connect continuously with your audiences',
    body: producer03,
    src: ProducerImg03,
    logo: elysiumCloud,
  },
  {
    index: 3,
    headline: 'Monetize your experiences through new revenue channels',
    body: producer04,
    src: ProducerImg04,
    logo: elysium,
  },
  {
    index: 4,
    headline: 'Core interoperability: Build interactive architectures for any platform',
    body: producer05,
    src: ProducerImg05,
    logo: elysiumPortal,
  },
]

const useStore = create((set: (a: any) => any) => {
  const items = [
    { position: [0.25, 1.8, -6], r: 0.5, geometry: new SphereBufferGeometry(1, 32, 32) },
    { position: [-2.5, 0, 2], r: 0.2, geometry: new TetrahedronBufferGeometry(2) },
    { position: [1, -0.75, 4], r: 0.3, geometry: new CylinderBufferGeometry(0.8, 0.8, 2, 32) },
    { position: [-1.7, 0.6, 6], r: 0.4, geometry: new ConeGeometry(1.1, 1.7, 32) },
    { position: [0.5, -2.2, -6], r: 0.9, geometry: new SphereBufferGeometry(1.5, 32, 32) },
    { position: [-0.5, 2.5, -2], r: 0.6, geometry: new IcosahedronBufferGeometry(2) },
    {
      position: [-1.1, -0.7, 3],
      r: 0.35,
      geometry: new TorusBufferGeometry(1.1, 0.35, 16, 32),
    },
    { position: [3.5, 0.5, -2], r: 0.8, geometry: new OctahedronGeometry(2) },
    { position: [-1, -0.5, -6], r: 0.5, geometry: new SphereBufferGeometry(1.5, 32, 32) },
    { position: [3, 1.9, -1], r: 0.2, geometry: new BoxBufferGeometry(2.5, 2.5, 2.5) },
    { position: [0, 2, 0], r: 1.9, geometry: null },
  ]
  return { items, material: new MeshStandardMaterial({ color: '#2c313f', roughness: 1 }) }
})

function Geometry({ r, position, ...props }) {
  const ref = useRef<Group>()
  useFrame((state) => {
    if (!ref.current) return
    ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z += 0.004 * r
    ref.current.position.y = position[1] + Math[r > 0.5 ? 'cos' : 'sin'](state.clock.getElapsedTime() * r) * r
  })
  return (
    <group position={position} ref={ref}>
      {/* @ts-ignore */}
      <a.mesh {...props} />
    </group>
  )
}

function Geometries() {
  const { items, material } = useStore()
  const transition = useTransition(items, {
    from: { scale: [0, 0, 0], rotation: [0, 0, 0] },
    enter: ({ r }) => ({ scale: [1, 1, 1], rotation: [r * 3, r * 3, r * 3] }),
    leave: { scale: [0.1, 0.1, 0.1], rotation: [0, 0, 0] },
    config: { mass: 5, tension: 1000, friction: 100 },
    trail: 100,
  })
  return transition((props, { position: [x, y, z], r, geometry }) =>
    geometry ? (
      <Geometry position={[x * 3, y * 3, z]} material={material} geometry={geometry} r={r} {...props} />
    ) : (
      <Model position={[x, 3, z]} />
    ),
  )
}

const Elysium = () => {
  const { color } = useSpring({ color: 0, from: { color: 1 }, config: { friction: 50 }, loop: true })
  return (
    <Layout>
      <Seo title="Elysium" description="A new paradigm for no-code XR worldbuilding" />
      <Fade>
        <StaticImage
          className="elysium-logo-main"
          src="../assets/svg/Elysium_full.svg"
          alt="Elysium logo"
          style={{
            marginBottom: '2rem',
            marginLeft: '9rem',
            marginTop: '7rem',
            width: 550,
            height: 166,
          }}
        />
      </Fade>
      <section style={{ padding: 0 }}>
        <Canvas
          dpr={1.25}
          camera={{ position: [0, 0, 15], near: 5, far: 40 }}
          style={{ gridColumn: '1 / span 12', padding: 0 }}
        >
          <a.fog
            attach="fog"
            args={['#2DF5C2', 10, 40]}
            color={color.to([0, 0.1, 0.5, 0.8, 1], ['#2DF5C2', '#F9005D', '#2DF5C2', '#F9005D', '#2DF5C2'])}
          />
          <ambientLight intensity={0.8} color={'#2c313f'} />
          <directionalLight castShadow position={[2.5, 12, 12]} intensity={4} />
          <pointLight position={[20, 20, 20]} />
          <pointLight position={[-20, -20, -20]} intensity={4} />
          <Suspense fallback={null}>
            <Geometries />
          </Suspense>
        </Canvas>
      </section>
      <section className="ten-central" style={{ display: 'block' }}>
        <Fade>
          <h1 style={{ marginTop: '-8%', maxWidth: 550, textAlign: 'left' }}>
            The new paradigm for <br></br>no-code XR worldbuilding
          </h1>
        </Fade>
        <Fade>
          <p style={{ marginTop: '10%' }}>
            ELYSIUM is an integrated App + Cloud platform for <b>persistent</b> + <b>precision geolocated</b>{' '}
            Augmented Reality (AR)
          </p>
          <p>
            ELYSIUM empowers <b>real-time</b> creation + deployment of <b>interactive AR</b> experiences
            ELYSIUM is the evolution of engagement that allows you to own and tell <b>your story</b> like
            never before
          </p>
        </Fade>
      </section>
      <h2
        className="for-creators"
        style={{
          marginTop: '-5.2%',
          maxWidth: 550,
          textAlign: 'left',
          marginLeft: '9rem',
        }}
      >
        For Creators
      </h2>
      <section style={{ display: 'block' }}>
        <ElysiumCarousel slides={creatorData} heading="Features for creators" />
      </section>
      <section style={{ display: 'block', marginTop: '7rem' }}>
        <h2
          className="for-producers"
          style={{
            position: 'absolute',
            marginTop: '-6.5%',
            maxWidth: 550,
            textAlign: 'left',
            marginLeft: '9rem',
          }}
        >
          For Producers
        </h2>
        <ElysiumCarousel slides={producerData} heading="Features for Producers" />
      </section>
      <section className="ten-central">
        <Fade className="v-center" style={{ gridColumn: '3 / span 8' }}>
          <p>
            ELYSIUM represents a <b>new paradigm</b> of presence driven, embodied interactive design for the
            spatial metaverse
          </p>
        </Fade>
      </section>
      <section style={{ height: '50vh', display: 'flex', gap: 0, padding: 0 }}>
        <Link
          to="/contact"
          style={{
            background: '#2DF5C2',
            width: '50%',
            display: 'grid',
            textDecoration: 'none',
            color: '#000',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '1rem',
          }}
        >
          <span className="v-center">CONTACT US and sign-up for beta access</span>
        </Link>
        <a
          href="https://discord.gg/gYMKNYHJRJ"
          style={{
            background: '#7289DA',
            width: '50%',
            display: 'grid',
            textDecoration: 'none',
            color: '#FFF',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '1rem',
          }}
        >
          <span className="v-center">Sign up to our newsletter and join the conversation</span>
        </a>
      </section>
    </Layout>
  )
}

export default Elysium
