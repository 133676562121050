import React, { useRef } from 'react'
import { useState } from 'react'

import './elysiumslides.scss'

type SlideProps = {
  slide: {
    src: string
    icon: string
    headline: string
    body: React.ReactFragment
    index: number
    logo: string
  }
  current: number
  handleSlideClick: (index: number) => void
}

const Slide = (props: SlideProps) => {
  const ref = useRef<HTMLLIElement>()

  const imageLoaded = (event) => {
    event.target.style.opacity = 1
  }

  const { src, headline, index, icon, logo } = props.slide

  const current = props.current
  let classNames = 'eslide'

  if (current === index) classNames += ' eslide--current'
  else if (current - 1 === index) classNames += ' eslide--previous'
  else if (current + 1 === index) classNames += ' eslide--next'

  return (
    <li
      ref={ref}
      className={classNames}
      onClick={() => props.handleSlideClick(props.slide.index)}
      onTouchStart={() => props.handleSlideClick(props.slide.index)}
    >
      <div className="eslide__wrapper radical">
        <img className="eslide__image" alt={headline} src={src} onLoad={imageLoaded} />
        {icon}
        <article className="eslide__content">
          <div className="eslide__container">
            <img className="eslide__logo" src={logo} />
            <h2 className="eslide__headline">{headline}</h2>
          </div>
          {props.slide.body}
        </article>
        <span className="eslide__index">.{(props.slide.index + 1).toString().padStart(2, '0')}</span>
      </div>
    </li>
  )
}

const SliderControl = ({ title, slides, handleSlideClick, current }) => {
  return (
    <div className="eslide__btnWrapper">
      {slides.map((slide) => {
        return (
          <button
            style={{ opacity: current === slide.index ? '0.4' : '1' }}
            className="eslide__btn"
            title={title}
            key={slide.index}
            onClick={() => handleSlideClick(slide.index)}
          >
            .0{slide.index + 1}
          </button>
        )
      })}
    </div>
  )
}

const Slider = ({ slides, heading }) => {
  const [current, setCurrent] = useState(0)

  const handleSlideClick = (index) => {
    if (current !== index) {
      setCurrent(index)
    }
  }

  const headingId = `eslider-heading__${heading.replace(/\s+/g, '-').toLowerCase()}`
  const wrapperTransform = {
    transform: `translateX(-${current * (100 / slides.length)}%)`,
  }

  return (
    <div className="eslider" aria-labelledby={headingId}>
      <ul className="eslider__wrapper" style={wrapperTransform}>
        <h3 id={headingId} className="visuallyhidden">
          {heading}
        </h3>
        {slides.map((slide) => {
          return (
            <Slide key={slide.index} slide={slide} current={current} handleSlideClick={handleSlideClick} />
          )
        })}
      </ul>
      <div className="eslider__controls">
        <SliderControl
          title="Go to the slide specified"
          slides={slides}
          handleSlideClick={handleSlideClick}
          current={current}
        />
      </div>
    </div>
  )
}

export default function ElysiumCarousel({ slides, heading }) {
  return <Slider slides={slides} heading={heading} />
}
